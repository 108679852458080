import { useEffect } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/compat/router";
import NextLink from "next/link";
import { Box, Button, IconFa, Loader } from "@cruk/cruk-react-components";
import { useInView } from "react-intersection-observer";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { useSwrGet } from "@fwa/src/hooks/useSwrGet";
import { queryAsString } from "@fwa/src/utils/urlUtils";
import { userNameForPage, firstNameForPage } from "@fwa/src/utils/pageUtils";
import {
  fwsUrlFundraisingPageSlug,
  updatePage,
} from "@fwa/src/services/fundraisingPageService";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";
import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";
import { isPast, isToday } from "@fwa/src/utils/timeUtils";
import { useModalContext } from "@fwa/src/contexts/ModalContext";
import { sendGTMTrackingEvent } from "@fwa/src/services/gtm";
import { imagePathFromImageType } from "@fwa/src/services/imageService";
import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";

import { EditableInMemoryForm } from "@fwa/src/components/EditableInMemoryForm";
import { ErrorPageWrapper } from "@fwa/src/components/ErrorPageWrapper";
import { FeedItemShared } from "@fwa/src/components/FeedItemShared";
import { FundraisingPageActivityBlock } from "@fwa/src/components/FundraisingPageActivityBlock";
import { FundraisingPageAdmin } from "@fwa/src/components/FundraisingPageAdmin";
import { RestrictionSection } from "@fwa/src/components/RestrictionSection";
import { Share } from "@fwa/src/components/Share";
import { ActivityManagementJourney } from "@fwa/src/components/ActivityManagementJourney";
import { PageHeaderImage } from "@fwa/src/components/PageHeaderImage";
import { StravaTracker } from "@fwa/src/components/StravaTracker";
import { FitbitTracker } from "@fwa/src/components/FitbitTracker";
import { FundraisingPageTotaliser } from "@fwa/src/components/FundraisingPageTotaliser";
import { TotaliserImpactCarousel } from "@fwa/src/components/TotaliserImpactCarousel";
import { FundraisingPageLatestUpdates } from "@fwa/src/components/FundraisingPageLatestUpdates";
import { FundraisingPageClosedInfo } from "@fwa/src/components/FundraisingPageClosedInfo";

import { PageTitle } from "@fwa/src/components/PageTitle";
import { PageSubtitle } from "@fwa/src/components/PageSubtitle ";
import { EditableDisplayNameForm } from "@fwa/src/components/EditableDisplayNameForm";
import { FundraisingPageVideo } from "@fwa/src/components/FundraisingPageVideo";

import {
  ContentMaxWidth,
  RowStretch,
  HeaderGroup,
  AvatarResponsiveWrapper,
  DividerLight,
} from "@fwa/src/components/styles";

import {
  ActionButtonWrapper,
  ActionButtonFlex,
  TotaliserButtonsFlex,
  DesktopRight,
  DesktopLeft,
  ColumnWrapper,
} from "@fwa/src/components/FundraisingPage/styles";

import {
  type FeedItemType,
  type FundraisingPageType,
  type PageType,
  type RestrictionType,
} from "@fwa/src/types";
import PageStory from "../PageStory";

const Gallery = dynamic(() => import("@fwa/src/components/Gallery"));
const FundraisingPageSettings = dynamic(
  () => import("@fwa/src/components/FundraisingPageSettings"),
);
const FundraisingPageModalsManager = dynamic(
  () => import("@fwa/src/components/FundraisingPageModalsManager"),
);
const FitbitConnectionError = dynamic(
  () => import("@fwa/src/components/FitbitConnectionError"),
);
const FacebookConnectionError = dynamic(
  () => import("@fwa/src/components/FacebookConnectionError"),
);

type Props = {
  pageData: FundraisingPageType;
  sharedFeedItemData?: FeedItemType;
};

export const FundraisingPage = ({ pageData, sharedFeedItemData }: Props) => {
  const [fundraiserState] = useFundraiserContext();
  const { trackEvent } = useOptimizelyContext();
  const { sendTrackingEvent, trackError } = useTrackingContext();
  const router = useRouter();
  //  figure out unique page url for page cacheId
  const { slug, feed } = router?.query || {};
  const slugString = queryAsString(slug);
  const feedString = queryAsString(feed);
  const feedId = sharedFeedItemData?.uniqueId || feedString || "";
  const [modalState] = useModalContext();

  const { ref: feedRef, inView: feedInView } = useInView({
    triggerOnce: true,
    rootMargin: "200px",
  });
  const { ref: galleryRef, inView: galleryInView } = useInView({
    triggerOnce: true,
    rootMargin: "200px",
  });

  const url = slugString.length
    ? `${fwsUrlFundraisingPageSlug({ slug: slugString })}`
    : null;
  // page cache

  const opts = { fallbackData: pageData };
  const {
    data: page,
    error,
    mutate,
  } = useSwrGet<FundraisingPageType>(url, opts);
  const pageFundraiser = page?.fundraiser;
  // Used updateing an attribute on the page object on FWS API and then mutating the local page object
  const updateAndMutatePage = async (
    partialData: Partial<FundraisingPageType>,
    refresh?: boolean,
  ) => {
    try {
      if ("activityName" in partialData) {
        trackEvent({ eventKey: "activity_name_updated" });
      }
      if ("activityDateTime" in partialData) {
        trackEvent({ eventKey: "activity_date_updated" });
      }
      if ("title" in partialData) {
        trackEvent({ eventKey: "title_updated" });
      }
      if ("videoUrl" in partialData) {
        trackEvent({ eventKey: "video_updated" });
      }
    } catch {
      // nothing
    }

    return updatePage({ pageId: page?.uniqueId || "", data: partialData })
      .then(
        (updatedPageData) =>
          mutate(
            updatedPageData,
            refresh || false,
          ) as Promise<FundraisingPageType>,
      )
      .catch((err) => {
        trackError(err as Error, { component: "FundraisingPage" });
      });
  };

  // this is when we want to mutate the page but the data is set via an alternative api endpoint
  // for example setting the header image which has its own endpoint to create an image entity before attaching to a page
  // but header image data still appears in page data so we can't use updateAndMutatePage.
  const mutatePage = (partialData: Partial<FundraisingPageType>) =>
    mutate({ ...page, ...partialData }, false) as Promise<
      undefined | void | FundraisingPageType
    >;

  const canEdit: boolean =
    !!fundraiserState?.fundraiser?.uniqueId &&
    fundraiserState?.fundraiser?.uniqueId === page?.fundraiser?.uniqueId;
  const isStravaMetric = page?.strava?.useMetric ?? true;
  const fitbitTargetSteps = page?.fitbit?.targetSteps || 0;
  const fitbitDuration =
    page?.fitbit?.startDate && page?.fitbit?.endDate
      ? Math.round(
          (new Date(`${page?.fitbit?.endDate}`).getTime() -
            new Date(`${page?.fitbit?.startDate}`).getTime()) /
            (1000 * 60 * 60 * 24),
        ) + 1
      : 0;
  const fitbitDailyTargetSteps = fitbitTargetSteps
    ? fitbitTargetSteps / fitbitDuration
    : 0;
  const fitbitValid = fundraiserState?.fundraiser?.fitbitUser?.valid ?? true;
  const isFacebookUserValid =
    fundraiserState?.fundraiser?.facebookUser?.valid ?? true;
  const isFacebookFundraiserValid =
    page?.facebookFundraiser && canEdit ? page.facebookFundraiser.valid : true;

  const userName = page ? userNameForPage(page) : "";
  const firstName = page ? firstNameForPage(page) : "";

  const avatarUrl =
    !page?.owner && page?.fundraiser?.profileImage
      ? page.fundraiser.profileImage.entityType === "ImageFacebook"
        ? `${page.fundraiser.profileImage.url}?width=300&height=300`
        : imagePathFromImageType(page.fundraiser?.profileImage)
      : "";

  const restriction: RestrictionType | null =
    page?.restriction ||
    page?.fundraisingPageType?.restriction ||
    page?.brand?.restriction ||
    page?.fundraisingPageType?.brand?.restriction ||
    null;

  const pageCloseDate = page?.closeDate ? new Date(page.closeDate) : null;
  const isPageClosed: boolean =
    page?.pageStatus === "closed" ||
    (pageCloseDate && (isToday(pageCloseDate) || isPast(pageCloseDate))) ||
    false;

  // If logged and canEdit in refresh because we might have non logged in data cached on load
  // this might be missing props like latestMilestone refetch

  useEffect(() => {
    const hasLogginInPageData =
      pageFundraiser && "emailAddress" in pageFundraiser;
    if (
      fundraiserState.loggedInStatus === "loggedIn" &&
      !hasLogginInPageData &&
      canEdit
    ) {
      mutate().catch((err) => {
        trackError(err as Error, { component: "FundraisingPage" });
      });
    }
  }, [fundraiserState, pageFundraiser, trackError, mutate, canEdit]);

  useEffect(() => {
    sendGTMTrackingEvent({
      event: "pageView",
      pagePath: "/fundraising-page",
      pageTitle: "fundraising page",
    });
  }, []);

  return (
    <ErrorPageWrapper error={error!}>
      {/* Display modal */}
      {modalState}
      <ContentMaxWidth marginBottom="xxl">
        {/* Loading state */}
        {!page ? (
          <Loader />
        ) : (
          <>
            {/* Data state */}
            <ActivityManagementJourney page={page} />

            {canEdit && page?.facebookFundraiser && (
              <FacebookConnectionError
                page={page}
                isValid={isFacebookUserValid && isFacebookFundraiserValid}
              />
            )}
            {canEdit && page?.fitbit && (
              <FitbitConnectionError page={page} isValid={fitbitValid} />
            )}

            {canEdit && <FundraisingPageModalsManager page={page} />}

            {/* Top actions block */}
            <Box backgroundColor="backgroundLight" padding="s" margin="none">
              <RowStretch data-component="page-actions-top">
                <Share
                  page={page}
                  feedItem={null}
                  isOwner={canEdit}
                  popoverPosition="bottom"
                  ctaLocation="header"
                >
                  <Button
                    type="button"
                    appearance="secondary"
                    data-cta-type="open-share"
                  >
                    <IconFa faIcon={faShareAlt} />
                    Share
                  </Button>
                </Share>
                {!isPageClosed && (
                  <NextLink
                    href={`/donate/${page.uniqueId}/details`}
                    onClick={() => {
                      sendTrackingEvent({
                        event: "donate_cta",
                        cta: "header",
                      });
                    }}
                    data-cta-type="donate"
                  >
                    <Button appearance="primary" as="span">
                      Donate
                    </Button>
                  </NextLink>
                )}
              </RowStretch>
            </Box>
            {/* Shared feedItem */}
            {(sharedFeedItemData || !!feedString.length) && (
              <FeedItemShared
                feedId={feedId}
                pageId={page.uniqueId}
                pageUrl={page.url}
                isStravaMetric={isStravaMetric}
                fitbitDailyTargetSteps={fitbitDailyTargetSteps}
                sharedFeedItemData={sharedFeedItemData}
              />
            )}

            <Box marginBottom="m">
              <Box
                backgroundColor="backgroundLight"
                padding="none"
                margin="none"
                data-component="page-display-name"
              >
                <AvatarResponsiveWrapper>
                  <EditableDisplayNameForm
                    avatarUrl={avatarUrl}
                    canEdit={canEdit}
                    fieldName="display name"
                    handleEditData={updateAndMutatePage}
                    userName={
                      page.owner?.displayName
                        ? page.owner.displayName
                        : userName
                    }
                    page={page}
                  />
                </AvatarResponsiveWrapper>
              </Box>
              {/* TitleBlock */}
              <HeaderGroup>
                <PageTitle
                  canEdit={canEdit}
                  page={page}
                  updateAndMutatePage={updateAndMutatePage}
                />
                {/* Header image  */}
                <Box marginBottom="none" data-component="page-header-image">
                  <PageHeaderImage
                    canEdit={canEdit}
                    page={page}
                    mutatePage={mutatePage}
                  />
                </Box>
              </HeaderGroup>

              <Box backgroundColor="backgroundLight" padding="s" margin="none">
                {/* Totaliser */}
                <Box marginBottom="xs" data-component="page-totaliser">
                  <FundraisingPageTotaliser
                    canEdit={canEdit}
                    page={page}
                    updateAndMutatePage={updateAndMutatePage}
                  />
                </Box>
                {restriction === null ? (
                  <TotaliserImpactCarousel page={page} />
                ) : null}
                <TotaliserButtonsFlex data-component="page-totaliser-ctas">
                  <Share
                    page={page}
                    feedItem={null}
                    isOwner={canEdit}
                    popoverPosition="top"
                    ctaLocation="totaliser"
                  >
                    <Button
                      type="button"
                      appearance="secondary"
                      data-cta-type="open-share"
                    >
                      <IconFa faIcon={faShareAlt} />
                      Share
                    </Button>
                  </Share>
                  {!isPageClosed && (
                    <NextLink
                      href={`/donate/${page.uniqueId}/details`}
                      onClick={() => {
                        sendTrackingEvent({
                          event: "donate_cta",
                          cta: "totaliser",
                        });
                      }}
                      data-cta-type="donate"
                    >
                      <Button appearance="primary" as="span">
                        Donate
                      </Button>
                    </NextLink>
                  )}
                </TotaliserButtonsFlex>
              </Box>

              {/* ActivityBlock */}
              <FundraisingPageActivityBlock
                canEdit={canEdit}
                page={page}
                updateAndMutatePage={updateAndMutatePage}
              />

              {/* Page connections settings section */}
              {canEdit && (
                <Box marginVertical="m">
                  <FundraisingPageSettings
                    page={page}
                    updateAndMutatePage={updateAndMutatePage}
                  />
                </Box>
              )}
              {isPageClosed && (
                <Box marginBottom="m" data-component="page-closed-info">
                  <FundraisingPageClosedInfo
                    fundraiserId={page.fundraiser.uniqueId}
                    userName={userName}
                  />
                </Box>
              )}
            </Box>

            <ColumnWrapper>
              <DesktopRight>
                {/* connections, enhance section  */}
                {canEdit && (
                  <Box marginBottom="m">
                    <FundraisingPageAdmin page={page} mutatePage={mutatePage} />
                  </Box>
                )}
                {/* Activity trackers */}
                {(page.strava || page.fitbit) && (
                  <Box backgroundColor="backgroundLight">
                    {page.strava && (
                      <Box data-component="strava-tracker">
                        <StravaTracker
                          canEdit={canEdit}
                          strava={page.strava}
                          mutatePage={mutatePage}
                        />
                      </Box>
                    )}
                    {page.strava && page.fitbit && (
                      <Box>
                        <DividerLight />
                      </Box>
                    )}
                    {page.fitbit && (
                      <Box data-component="fitbit-tracker">
                        <FitbitTracker
                          canEdit={canEdit}
                          fitbit={page.fitbit}
                          mutatePage={mutatePage}
                        />
                      </Box>
                    )}
                  </Box>
                )}
                {/* subtitle and story block */}
                <Box backgroundColor="lightBackground">
                  <PageSubtitle
                    page={page}
                    canEdit={canEdit}
                    updateAndMutatePage={updateAndMutatePage}
                  />
                  {/* In memory section */}
                  <EditableInMemoryForm
                    page={page}
                    name={page.activityInMemory}
                    handleEditData={updateAndMutatePage}
                    canEdit={canEdit}
                  />

                  <PageStory
                    page={page}
                    updateAndMutatePage={updateAndMutatePage}
                    canEdit={canEdit}
                  />

                  <FundraisingPageVideo
                    page={page}
                    canEdit={canEdit}
                    updateAndMutatePage={updateAndMutatePage}
                  />

                  <div id="gallery" ref={galleryRef}>
                    {galleryInView && (
                      <Gallery canEdit={canEdit} pageId={page.uniqueId} />
                    )}
                  </div>
                </Box>
                <Box marginBottom="m">
                  <RestrictionSection restriction={restriction} />
                </Box>
              </DesktopRight>

              <DesktopLeft>
                <div ref={feedRef} id="post">
                  {/* // id=post set to allow progress section to scroll as feed section
                  may not be loaded. */}
                  <FundraisingPageLatestUpdates
                    canEdit={canEdit}
                    page={page}
                    url={url}
                    feedInView={feedInView}
                    isStravaMetric={isStravaMetric}
                    fitbitDailyTargetSteps={fitbitDailyTargetSteps}
                    firstName={firstName}
                  />
                </div>
              </DesktopLeft>
            </ColumnWrapper>
          </>
        )}
      </ContentMaxWidth>
      {page ? (
        <ActionButtonWrapper paddingHorizontal="s" paddingVertical="xxs">
          <ContentMaxWidth>
            <ActionButtonFlex data-component="page-bottom-ctas">
              <Share
                page={page as PageType}
                feedItem={null}
                isOwner={canEdit}
                popoverPosition="top"
                ctaLocation="bottom-ctas"
              >
                <Button
                  type="button"
                  appearance="secondary"
                  data-cta-type="open-share"
                >
                  <IconFa faIcon={faShareAlt} />
                  Share
                </Button>
              </Share>
              {!isPageClosed && (
                <NextLink
                  href={`/donate/${page.uniqueId}/details`}
                  onClick={() => {
                    sendTrackingEvent({
                      event: "donate_cta",
                      cta: "bottom_bar",
                    });
                  }}
                  data-cta-type="donate"
                >
                  <Button appearance="primary" as="span">
                    Donate
                  </Button>
                </NextLink>
              )}
            </ActionButtonFlex>
          </ContentMaxWidth>
        </ActionButtonWrapper>
      ) : null}
    </ErrorPageWrapper>
  );
};

export default FundraisingPage;
